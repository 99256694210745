@import '../../variables.scss';
.hide{
    display: none;
}
.menu{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 10vw;
    height: 100vh;
    background: $negro;
    margin: 0;
    padding: 0;
    color: $blanco;
    position: relative;
    z-index: 10;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.75);
}
.logo{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10vw;
    height: 10vw;
    border-bottom: solid 1px $blanco;
    background: black;
    img{
        width: 80%;
    }
    h1{
        position: relative;
        font-size: 8vmin;
        margin: 0;
        padding: 0;
        color: $rojo;
        span{
            position: absolute;
            color: $blanco;
            font-size: 3vmin;
            left: 4vmin;
            bottom: 0vmin;
        }
    }
}
.lista{
    display: flex;
    flex-direction: column;
    width: 10vw;
    height: auto;
    a{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: $blanco;
        width: 10vw;
        height: 15vh;
        border-bottom: solid 1px $blanco;
        transition: all .3s ease-out;
        i{
            font-size: 4vmin;
            margin: 0;
            padding: 0;
        }
        p{
            margin: 0;
            padding: 1vmin;
        }
        &.activo{
            background: $blanco;
            color: $negro;
        }
    }
}


