@import '../../variables.scss';

.item{
    box-sizing: border-box;
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2vmin;
    font-size: 2vmin;
    div{
        text-align: left;
        width: 100%;
    }
}