@import '../../variables.scss';

.boton{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2vmin;
}

.filaLarga{
    height: 30vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.scrolleable{
    height: 20vh;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    button{
        margin: auto;
    }
}
.botonLista{
    width: 30vw;
    margin: auto;
}
.fila{
    input[type="number"]{
        width: 30%;
    }
}
.separador{
    height: 5vh;
    width: 100%;
}