@import '../../variables.scss';

.contenedor{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 90vw;
    height: 100vh;
    
}

.botonera{
    box-sizing: border-box;
    width: 90vw;
    height: 10vw;
    margin: 0vmin 2vmin;
    button{
        margin: 2vmin;
    }
}
.listas{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 90vw;
    height: calc(100vh - 10vw);
}
.lista{
    box-sizing: border-box;
    width: 45vw;
    height: calc(100vh - 10vw);
    margin: 0vmin 2vmin;
    
}