.modal{
    position: absolute;
    width: 90vw;
    height: 100vh;
    left: 10vw;
    top:0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,.5);
}

.hidden{
    display: none;
}