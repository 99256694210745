@import '../../variables.scss';

.item{
    display: flex;
    height: 5vh;
    width: 100%;
    font-size: 2vmin;
    .texto{
        margin: auto;
    }
}