.item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2vmin;
    font-size: 2vmin;
    div{
        width: 100%;
    }
}
